import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"avatar-wrapper",attrs:{"align-center":"","column":""}},[_c('div',{attrs:{"align":"center","color":"transparent"}},[_c(VAvatar,{attrs:{"color":"transparent","size":"150"}},[_c(VImg,{staticClass:"profile-picture",attrs:{"src":require("../assets/photo.jpg")}})],1),_c('br'),_c('div',{staticClass:"display-3 font-weight-light black--text text-xs-center"},[_vm._v(" Maxim Betin ")]),_c('div',{staticClass:"display-1 font-weight-thin black--text text-xs-center"},[_vm._v(" DevOps Engineer ")])],1),_c(VRow,[_c(VCol,{staticClass:"mx-10 mt-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":""},on:{"click":_vm.linkedin}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-linkedin")]),_vm._v(" LinkedIn ")],1)],1),_c(VCol,{staticClass:"mx-10 mt-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":""},on:{"click":_vm.stack}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-stack-overflow")]),_vm._v(" StackOverflow ")],1)],1),_c(VCol,{staticClass:"mx-10 mt-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":""},on:{"click":_vm.github}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-github")]),_vm._v(" GitHub ")],1)],1),_c(VCol,{staticClass:"mx-10 mt-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":""},on:{"click":_vm.cv}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil-box")]),_vm._v(" CV ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }