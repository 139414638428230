import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"app":"","clipped-left":"","elevate-on-scroll":"","color":"#B0BEC5"}},[_c(VSpacer),_c('v-app-bar-items',{staticClass:"menu-button"},[_vm._l((_vm.menu),function(item){return _c(VBtn,{key:item.icon,staticClass:"menu-button hidden-sm-and-down",attrs:{"text":""},on:{"click":function($event){return _vm.$vuetify.goTo(item.link, '')}}},[_vm._v(_vm._s(item.title)+" ")])}),_c('div',{staticClass:"hidden-md-and-up"},[_c(VMenu,{staticClass:"menu-dropdown",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VAppBarNavIcon,_vm._g({},on))]}}])},[_c(VList,{staticClass:"menu-dropdown"},_vm._l((_vm.menu),function(item){return _c(VListItem,{key:item.id,staticClass:"menu-dropdown",on:{"click":function($event){return _vm.$vuetify.goTo(item.link, '')}}},[_c(VListItemTitle,{staticClass:"menu-dropdown"},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c(VSpacer)],2)],1),_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","fixed":"","bottom":"","right":"","color":"#78909C"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("mdi-chevron-up")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }