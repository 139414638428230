import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"background-color",attrs:{"fluid":"","id":"contact"}},[_c(VFlex,{staticClass:"contact-wrapper"},[_c(VLayout,{attrs:{"align-center":"","column":"","mt-10":"","mx-15":"","mb-2":""}},[_c(VFlex,{staticClass:"display-1 font-weight-light text-xs-center mb-1"},[_vm._v(" Contact ")])],1),_c(VDivider,{attrs:{"color":"gray"}}),_c(VLayout,{staticClass:"about-text",attrs:{"align-center":"","justify-center":"","column":"","my-10":"","mx-15":""}},[_c(VFlex,{attrs:{"align-center":""}},[_c(VRow,[_c(VCol,{staticClass:"mx-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":"","rounded":"","href":"https://www.linkedin.com/in/maxim-betin/"}},[_c(VIcon,[_vm._v("mdi-linkedin")])],1)],1),_c(VCol,{staticClass:"mx-8"},[_c(VBtn,{staticClass:"button",attrs:{"outlined":"","rounded":"","href":"https://www.instagram.com/maxim_93b/"}},[_c(VIcon,[_vm._v("mdi-instagram")])],1)],1)],1)],1),_c(VRow,{staticClass:"mt-10"},[_c('p',{staticClass:"font-weight-normal",staticStyle:{"text-align":"center"}},[_vm._v(" My preferred method of contact is through my LinkedIn "),_c('a',{attrs:{"href":"https://www.linkedin.com/in/maxim-betin/"}},[_vm._v("page")]),_vm._v(". ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }