import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"background-color",attrs:{"fluid":"","id":"skills"}},[_c(VFlex,{staticClass:"skills-wrapper"},[_c(VLayout,{attrs:{"align-center":"","column":""}},[_c(VFlex,{staticClass:"display-1 font-weight-light text-xs-center mb-1"},[_vm._v(" Skills ")])],1),_c(VDivider,{attrs:{"color":"gray"}}),_c('br'),_c(VCol,[_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Python","level":"85"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"PowerShell","level":"75"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Prometheus","level":"75"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"CI/CD","level":"75"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Grafana","level":"70"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Docker","level":"70"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"GCP","level":"70"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Windows Server","level":"70"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"IaC","level":"65"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Kubernetes","level":"65"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Linux","level":"65"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Azure","level":"60"}}),_c('skill-bar',{attrs:{"barSize":"small","fontColor":"white","barColor":"#B0BEC5","skill":"Networking","level":"55"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }