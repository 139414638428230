<template>
  <v-container fluid class="background-color" id="career">
    <v-flex class="career-wrapper">
      <v-layout align-center column mx-15 mb-2 mt-10>
        <v-flex class="display-1 font-weight-light text-xs-center mb-1">
          Career
        </v-flex>
      </v-layout>

      <v-divider color="gray"></v-divider>

      <v-flex class="text-xs-left">
        <h1 class="font-weight-light mt-10 mb-5 experience">
          Future prospects
        </h1>
        <div class="description">
          <p class="font-weight-normal description-text">
            A few years ago, I discovered DevOps, a field that perfectly aligns
            with my passion for helping others and continual learning. The
            appeal of DevOps lies in its breadth, covering many areas and
            allowing for a versatile range of tasks, which prevents monotony.
            Furthermore, it balances the development of technical skills with
            interpersonal ones—an aspect I believe is as valuable, if not more
            so, than mere technical proficiency.
          </p>
          <p class="font-weight-normal description-text">
            While my journey into DevOps has acquainted me with numerous
            technologies, Kubernetes has particularly piqued my interest. I've
            dedicated substantial time to understanding and working with it,
            honing my skills through hands-on practice. While there's always
            more to learn, I'm pleased with my current level of knowledge and
            proficiency.
          </p>
          <p class="font-weight-normal description-text">
            While I acknowledge the merit certifications can hold for some, I
            personally place higher value on the practical application and
            understanding of technologies. Although I earned the
            <a
              href="https://www.credential.net/828e8f35-dfeb-4335-819d-0ac651ad5c7f"
              >ACE certification from GCP</a
            >
            years ago, I found that the practical, hands-on experience I gained
            while working proved more valuable. I do not discount the potential
            benefits of certifications, such as the Certified Kubernetes
            Administrator (CKA), but I prefer to learn by doing, which I find
            more engaging and worthwhile.
          </p>
          <p class="font-weight-normal description-text">
            As I continue to explore DevOps, I am eager to apply my skills in
            dynamic environments that value both technical know-how and
            interpersonal skills. I look forward to engaging with projects that
            challenge me, broaden my horizons, and have meaningful impact.
          </p>
        </div>
      </v-flex>

      <!--      <v-flex class="text-xs-left">-->
      <!--        <h1 class="font-weight-light mt-10 mb-5 experience">-->
      <!--          My professional career-->
      <!--        </h1>-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            DevOps Engineer at Flowable, Valencia, Spain-->
      <!--            <span class="date">11/2020 - Present</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            Continuing my learning path in DevOps I decided to move on to new-->
      <!--            challenges and also learn a new Cloud provider - Microsoft Azure, by-->
      <!--            joining Flowable group - a Swiss BPM Software company which-->
      <!--            surprised me as to how well organized the working culture is, even-->
      <!--            during a pandemic - this was very evident during my onboarding-->
      <!--            process; I'm very appreciative of such consideration towards others.-->
      <!--            I’m working remotely and autonomously with no to little supervision.-->
      <!--            For the most part, I have the freedom and trust to appropriately-->
      <!--            prioritize both planned and unplanned topics. While also gaging the-->
      <!--            complexity, stakeholder’s requirements, urgency and other factors-->
      <!--            that come into play when committing to implementing or providing-->
      <!--            services.-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            I played a big role in Automation - scripting out repetitive and-->
      <!--            tedious tasks and workflows. Thus reducing human error and freeing-->
      <!--            up time to invest into more valuable tasks - using mainly Python,-->
      <!--            Bash, with some sprinkles of PowerShell and GitLab's CI/CD-->
      <!--            pipelines.-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            I am administering many Kubernetes-hosted applications such as-->
      <!--            GitLab, SonarQube and Nexus IQ - communicating and handling-->
      <!--            incidents, upgrades, migrations, evaluating improvements, etc.-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            I make new additions and keep improving the Monitoring and Alerting-->
      <!--            area to monitor Business-critical workloads, and thus solidifying-->
      <!--            the SLAs and increasing observability using Prometheus, Alertmanager-->
      <!--            and Opsgenie. I actively write new Runbooks to help in-->
      <!--            troubleshooting or generally finding one's way around through the-->
      <!--            use of comprehensive and extensively written documentation with a-->
      <!--            lot of attention to detail. An integration that I also played a big-->
      <!--            role and invested in was with the ERP system, where security and-->
      <!--            testing had a big emphasis to build a solid solution and have a-->
      <!--            reliable operational service.-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            When making changes on the infrastructure I follow an IaC-->
      <!--            methodology using Terraform so as to keep track, promote-->
      <!--            collaboration and minimize errors. Similarly, when configuration-->
      <!--            changes are required I use Ansible or leverage ArgoCD (GitOps).-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            I actively engage in the analysis, decision-making and-->
      <!--            implementation of internal processes related to-->
      <!--            <a href="https://en.wikipedia.org/wiki/ITIL">ITIL</a>.-->
      <!--          </p>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            And last but not least, I provide Support to both technical and-->
      <!--            non-technical co-workers, play a mentoring role, and took part in-->
      <!--            interviews that have contributed in new talent acquisitions.-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <br />-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            DevOps Engineer at Planeta Huerto, Alicante, Spain-->
      <!--            <span class="date">10/2020 - 11/2020</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            During my stay in Barcelona, I had a growing concern in the back of-->
      <!--            my head in regards to not having finished one subject in my Computer-->
      <!--            Engineering degree, namely-->
      <!--            <a-->
      <!--              href="https://cvnet.cpd.ua.es/Guia-Docente/?wlengua=en&wcodasi=34027&scaca=2020-21"-->
      <!--              >SSPT</a-->
      <!--            >. I decided to leave Barcelona to get that matter settled with in-->
      <!--            Alicante (and I did), with plans of coming back. To my misfortune-->
      <!--            (and everyone's really) the world decided it was time to spread a-->
      <!--            pandemic, and long story short, I could not return in May.-->
      <!--            Fortunately enough, I used the summer time to get-->
      <!--            <a-->
      <!--              href="https://www.credential.net/828e8f35-dfeb-4335-819d-0ac651ad5c7f"-->
      <!--              >certified</a-->
      <!--            >-->
      <!--            in Google Cloud Platform, create this website, and consider what-->
      <!--            professional course to take next. I was looking for something that-->
      <!--            had some sort of Programming, Technical Support and Cloud Computing-->
      <!--            mixed in - things that I worked with in the past, and hence, DevOps-->
      <!--            appeared in my Google search. While staying in Alicante I found and-->
      <!--            joined <a href="https://www.planetahuerto.es/">Planeta Huerto</a> - -->
      <!--            an e-commerce company that sells environmentally friendly products-->
      <!--            in Spain and Portugal. And henceforth, I was officially sold on the-->
      <!--            DevOps concept and started dealing with and learning a lot about-->
      <!--            Kubernetes, Docker, System Administration, CI/CD, IaC, Monitoring-->
      <!--            and Logging, Incident Management, Agile Methodologies, etc. Planeta-->
      <!--            Huerto coincidentally used both PHP and Vue.js - the two web-->
      <!--            technologies I also used in the past so they captured my attention.-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <br />-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            Technical Solutions Representative for Google Cloud Platform at-->
      <!--            Webhelp Group, Barcelona, Spain-->
      <!--            <span class="date">09/2018 - 01/2020</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            After having almost completed my Computer Engineering degree, I-->
      <!--            joined-->
      <!--            <a href="https://www.webhelp.com/en-es/">Webhelp Group</a>-->
      <!--            (previously known as Sellbytel Group), more specifically the-->
      <!--            <a href="https://cloud.google.com/">Google Cloud Platform</a>-->
      <!--            project as a Junior Java Developer and a Technical Solutions-->
      <!--            Representative. Here’s where my knowledge of the IT world grew-->
      <!--            exponentially. I learned a lot about the Cloud services major-->
      <!--            companies such as Google provide, especially the ones related to-->
      <!--            Serverless, Database and Storage, and how they can interact with-->
      <!--            each other. I touched upon multiple programming languages including-->
      <!--            Java, Python, Node.js, PHP and Go; relational (MySQL, PostgreSQL,-->
      <!--            Microsoft SQL Server) and non-relational databases; I extensively-->
      <!--            used different Internet protocols: HTTP(S), DNS, TLS/SSL, TCP, UDP,-->
      <!--            SMTP, SSH, TCP/IP, etc.; APIs such as REST; authentication standards-->
      <!--            such as OAuth; version control systems such as Git and a very heavy-->
      <!--            emphasis on Bash/Shell and the Linux/Unix operative systems. I also-->
      <!--            learned about and started experimenting with containerization-->
      <!--            systems such as Docker and Kubernetes. I was mainly tasked with-->
      <!--            troubleshooting anything related with the aforementioned points,-->
      <!--            engaging with English-speaking customers of GCP from all over the-->
      <!--            world.-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <br />-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            Full Stack Web Developer for NewPyme S.L at Alicante, Spain-->
      <!--            <span class="date">05/2017 - 11/2017</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            My professional career started with an internship at the consulting-->
      <!--            company <a href="https://www.newpyme.com/">NewPyme S.L</a>. I was-->
      <!--            tasked with mainly Full Stack web development using PHP, JavaScript,-->
      <!--            HTML, CSS and MySQL, developing several modules for the website-->
      <!--            which were deployed to production - here’s where I also learned-->
      <!--            exactly how the production and development environments are-->
      <!--            separated and work. Apache HTTP Server was also a heavy area I was-->
      <!--            involved in – modifying and maintaining the configuration I applied-->
      <!--            in a Linux environment, fulfilling system administrator tasks. I-->
      <!--            also worked on SSL/TLS certificates and web application security was-->
      <!--            one of my main points to keep in mind – which I finished a course on-->
      <!--            provided by the company. My final project here was the development-->
      <!--            of an accounting tool.-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </v-flex>-->
      <!--      -->
      <!--      <v-flex class="text-xs-left">-->
      <!--        <h1 class="font-weight-light mt-10 mb-5 experience">-->
      <!--          My academic career-->
      <!--        </h1>-->
      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            Degree in Computer Engineering at the University of Alicante, Spain-->
      <!--            <span class="date">2013 - 2018</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            I studied Computer Engineering at UA (University of Alicante,-->
      <!--            Spain). During my final year, I opted for the hardware-->
      <!--            specialization side of my degree although I was later on swayed and-->
      <!--            captivated by writing code and developing software instead,-->
      <!--            especially once I delved deep into it while writing my final thesis-->
      <!--            (it was the fulcrum to an extent), which consisted in the use of-->
      <!--            trigonometry, statistics and the Gaussian function to detect-->
      <!--            patterns of bullying in primary (or elementary) schools by analyzing-->
      <!--            the positioning of each student during their breaks at the-->
      <!--            schoolyard with the help of GPS coordinates. The application also-->
      <!--            has a user interface with the intent of helping the final user (in-->
      <!--            this case the teacher) in discarding or further analyzing the-->
      <!--            detection, all written in the Java framework and the Eclipse IDE –-->
      <!--            you can find it-->
      <!--            <a href="http://rua.ua.es/dspace/handle/10045/80396?locale=en"-->
      <!--              >here</a-->
      <!--            >. I was also in the-->
      <!--            <a-->
      <!--              href="https://eps.ua.es/en/ingenieria-informatica/curriculum/high-academic-performance-groups.html"-->
      <!--              >ARA</a-->
      <!--            >-->
      <!--            (Alto Rendimiento Académico or High Academic Performance in English)-->
      <!--            group – a small group of students who registered the highest grades-->
      <!--            during university admissions and chose to have most of their-->
      <!--            subjects in English.-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <br />-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            Baccalaureate at CMT La Purísima, Torrevieja, Spain-->
      <!--            <span class="date">2011 - 2013</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            After moving back to Spain, my IB education was unfortunately-->
      <!--            halted. To my surprise and sadly, I wasn't able to continue where I-->
      <!--            left off - having to start over, and losing 2 years of education in-->
      <!--            the process. Due to IB not being compatible with Spain’s regular-->
      <!--            baccalaureate, and not being free unlike in Sweden (in fact it is-->
      <!--            very pricy), I had little choice. I joined a semi-private High-->
      <!--            School called-->
      <!--            <a href="http://www.carmelitastorrevieja.com/">CMT La Purísima</a>.-->
      <!--            Once again, I excelled at Physics, Mathematics and English. My-->
      <!--            average mark of the two years I spent here was of 8,12/10.-->
      <!--          </p>-->
      <!--        </div>-->

      <!--        <br />-->

      <!--        <div class="description">-->
      <!--          <h2 class="font-weight-light position">-->
      <!--            International Baccalaureate at Katedralskolan, Lund, Sweden-->
      <!--            <span class="date">2008 - 2011</span>-->
      <!--          </h2>-->
      <!--          <p class="font-weight-normal description-text">-->
      <!--            My first two years of education in Baccalaureate were in Lund,-->
      <!--            Sweden. I studied-->
      <!--            <a href="https://www.ibo.org/">International Baccalaureate</a>, at-->
      <!--            <a href="https://www.lund.se/en/katedralskolan/">Katedralskolan</a>.-->
      <!--            All subjects were in English (except Swedish of course), and here’s-->
      <!--            where I first contemplated and considered what would I study in-->
      <!--            college. Physics and Mathematics were my strongest points, thus-->
      <!--            naturally, these were my initial prospects, Computer Engineering was-->
      <!--            on the horizon as well, but I wouldn't finally decide until a few-->
      <!--            years later. The-->
      <!--            <a href="https://www.ibo.org/programmes/diploma-programme/"-->
      <!--              >IB Diploma Programme</a-->
      <!--            >-->
      <!--            consists of 3 years, unlike the regular 2 years in Spain. My parents-->
      <!--            decided to move back to Spain after I finished my second year, so I-->
      <!--            was unable to complete it.-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </v-flex>-->
    </v-flex>
  </v-container>
</template>

<style>
.career-wrapper {
  margin-left: 15vw;
  margin-right: 15vw;
  color: black;
}

.experience {
  border-bottom: 5px solid #78909c;
  display: inline-block;
}

.position {
  display: inline-block;
  margin-bottom: 3px;
  color: black;
}

.description {
  border-left: dotted;
  border-width: 4px;
  color: #b0bec5;
  padding-left: 10px;
  margin-top: 15px;
}

.description-text {
  color: black;
  text-align: justify;
}

.date {
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #90a4ae;
  padding: 1px 5px;
  border-radius: 3px;
}
</style>

<script>
export default {
  name: "Career"
};
</script>
